import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import * as _ from 'lodash';
import * as moment from 'moment';
type Label = string[];
import { EventsAttendanceService } from '../../services/events-attendance.service';
import { ProfileService } from '../../services/profile.service';

@Component({
  selector: 'app-calendar-event-dashboard',
  templateUrl: './calendar-event-dashboard.component.html',
  styleUrls: ['./calendar-event-dashboard.component.scss']
})
export class CalendarEventDashboardComponent implements OnInit {

barData: any;
engagementData: any;

isStaff = this.profileService.isStaff;

  

public barChartOptions: ChartOptions = {
  responsive: true,
};
public barChartLabels: Label[] = [];
public barChartType: ChartType = 'bar';
public barChartLegend = true;
public barChartPlugins = [];

  public barChartData: ChartDataset[] = [
    { data: [], label: 'Web', stack: 'a' },
    { data: [], label: 'Mobile', stack: 'a' }
  ];



role = this.profileService.profile.role;
eventId: any;
eventData: any;


constructor(
  private router: Router,
  private profileService: ProfileService,
  private eventService: EventsAttendanceService,
  private activeRoute: ActivatedRoute
) { }

ngOnInit(): void {
  this.activeRoute.params.subscribe((data) => {
    if (data.id) {
      this.eventId = data.id;


      this.eventService.getAttendanceById(this.eventId).subscribe(res => {
        this.eventData = res;
        this.engagementData = this.processEngagementData(this.eventData);
        this.barData = this.eventData.registrations;
        this.getBarChartData();
      })
    }
  })
}

goBack() {
  let role = this.role.toLocaleLowerCase();
  if(this.isStaff){
    this.router.navigate([role, 'communications-log']);
  } else {
    this.router.navigate([role, 'feeds']);
  }
}

getCheckIns(data, type) {
  const startDate = moment(data.startDate);
  const endDate = moment(data.endtDate);
  let checkIns = [];

  if(type == 'All'){
    _.each(data.registrations, attendee => {
      let checkInTime = moment(attendee.subscriptionDate);
      if(checkInTime.isBetween(startDate, endDate)){
        checkIns.push(attendee);
      }
    });
    return checkIns
  }

  if(type == 'Parent'){
    _.each(data.registrations, attendee => {
      let checkInTime = moment(attendee.subscriptionDate);
      if(attendee.role == 'Parent'){
        if(checkInTime.isBetween(startDate, endDate)){
          checkIns.push(attendee);
        }
      }
    });
    return checkIns
  }

  if(type == 'Student'){
    _.each(data.registrations, attendee => {
      let checkInTime = moment(attendee.subscriptionDate);
      if(attendee.role == 'Parent'){
        if(checkInTime.isBetween(startDate, endDate)){
          checkIns.push(attendee);
        }
      }
    });
    return checkIns
  }

}

roundToNearest5Percent(num, total) {
  if(total !== 0){
    return Math.round(((num / total) * 100) / 5) * 5;
  } else return 0
}

getPercent(num, total){
  if(total !== 0){
    return Math.round((num / total) * 100);
  } else return 0
}

processEngagementData(data) {


  let engagementData = [
    {
      label: '',
      received: 0,
      numRsvp: 0,
      percentRsvp: 0,
      numCheckedIn: 0,
      percentCheckedIn: 0,
      webCheckIns: 0,
      webPercent: 0,
      mobileCheckIns: 0,
      mobilePercent: 0
    },
    {
      label: '',
      received: 0,
      numRsvp: 0,
      percentRsvp: 0,
      numCheckedIn: 0,
      percentCheckedIn: 0,
      webCheckIns: 0,
      webPercent: 0,
      mobileCheckIns: 0,
      mobilePercent: 0
    },
    {
      label: '',
      received: 0,
      numRsvp: 0,
      percentRsvp: 0,
      numCheckedIn: 0,
      percentCheckedIn: 0,
      webCheckIns: 0,
      webPercent: 0,
      mobileCheckIns: 0,
      mobilePercent: 0
    },
  ];
 

  let totalCheckins = [];
  _.each(data.registrations, attendee => {
    totalCheckins.push(attendee)
  })

  let students = [];
  _.each(totalCheckins, student => {
    if(student.role == 'Student') {
      students.push(student);
    }
  })

  let parents = [];
  _.each(totalCheckins, parent => {
    if(parent.role == 'Parent') {
      parents.push(parent);
    }
  })

  //Total

  let totalRow = engagementData[0];
  totalRow.label = 'Total';
  totalRow.received = data.totalParents + data.totalStudents;
  _.each(data.registrations, attendee => {
    if(attendee.rsvp != null){
      totalRow.numRsvp++
    }
  })
  if(totalRow.numRsvp > 0) {
    totalRow.percentRsvp = this.roundToNearest5Percent(totalRow.numRsvp, totalRow.received);
  }
  _.each(totalCheckins, attendee => {
    if(attendee.checkIn !== null){
      totalRow.numCheckedIn++
    }
  })
  if(totalRow.numCheckedIn > 0) {
    totalRow.percentCheckedIn = Math.round(((totalRow.numCheckedIn / totalRow.received) * 100) / 5) * 5;
  }
  _.each(totalCheckins, attendee => {
    if(attendee.channel == 'Web') {
      totalRow.webCheckIns++
    }
    if(attendee.channel.includes('Mobile')) {
      totalRow.mobileCheckIns++
    }
  })
  if(totalRow.webCheckIns > 0){
    totalRow.webPercent = this.getPercent(totalRow.webCheckIns, totalRow.numCheckedIn)
  }
  if(totalRow.mobileCheckIns > 0){
    totalRow.mobilePercent = this.getPercent(totalRow.mobileCheckIns, totalRow.numCheckedIn)
  }

 
  //Parent Only

  let parentRow = engagementData[1];
  parentRow.label = 'Parents Only';
  parentRow.received = data.totalParents;
  _.each(parents, parent => {
    if(parent.rsvp != null){
      parentRow.numRsvp++
    }
  });
  parentRow.percentRsvp = this.roundToNearest5Percent(parentRow.numRsvp, parentRow.received);
  _.each(parents, parent => {
    if(parent.checkIn !== null){
      parentRow.numCheckedIn++
    }
  })
  parentRow.percentCheckedIn = this.roundToNearest5Percent(parentRow.numCheckedIn, parentRow.received);
  _.each(parents, parent => {
    if(parent.channel == 'Web'){
      parentRow.webCheckIns++
    }
    if(parent.channel.includes('Mobile')){
      parentRow.mobileCheckIns++
    }
  })

  if(parentRow.webCheckIns > 0){
    parentRow.webPercent = this.getPercent(parentRow.webCheckIns, parentRow.numCheckedIn);
  }
  if(parentRow.mobileCheckIns > 0){
    parentRow.mobilePercent = this.getPercent(parentRow.mobileCheckIns, parentRow.numCheckedIn);
  }


  //Student Only
  let studentRow = engagementData[2];
  studentRow.label = 'Students Only';
  studentRow.received = data.totalStudents;
  _.each(students, student => {
    if(student.rsvp != null){
      studentRow.numRsvp++
    }
  });
  studentRow.percentRsvp = this.roundToNearest5Percent(studentRow.numRsvp, studentRow.received);
  _.each(students, student => {
    if(student.checkIn != null){
      studentRow.numCheckedIn++
    }
  });
  studentRow.percentCheckedIn = this.roundToNearest5Percent(studentRow.numCheckedIn, studentRow.received);
  _.each(students, student => {
    if(student.channel == 'Web'){
      studentRow.webCheckIns++
    }
    if(student.channel.includes('Mobile')){
      studentRow.mobileCheckIns++
    }
  })

  if(studentRow.webCheckIns > 0){
    studentRow.webPercent = this.getPercent(studentRow.webCheckIns, studentRow.numCheckedIn);
  }
  if(studentRow.mobileCheckIns > 0){
    studentRow.mobilePercent = this.getPercent(studentRow.mobileCheckIns, studentRow.numCheckedIn);
  }


  console.log(engagementData)
  return engagementData
}

// processEngagementData(data) {
//   let engagementData = [];
//   const model = {
//     title: '',
//     audience: 0,
//     numAddCal: 0,
//     percentAddCal: 0,
//     numCheckedIn: 0,
//     percentCheckedIn: 0,
//     webCheckIns: 0,
//     mobileCheckIns: 0,
//   }

//   //total
//   let allRow = model;
//   allRow.title = 'All Audience'
//   allRow.audience = data.totalParents + data.totalStudents;
//   allRow.numAddCal = data.totalAddedToCalParents + data.totalAddedToCalStudents;
//   allRow.percentAddCal = Math.round(((allRow.numAddCal / allRow.audience) * 100) / 5) * 5;
//   allRow.numCheckedIn = this.getCheckIns(data, 'All').length;
//   allRow.percentCheckedIn = Math.round(((allRow.numAddCal / allRow.audience) * 100) / 5) * 5;
  
//   let totalWebCount = 0;
//   let totalMobileCount = 0;

//   _.each(data.registrations, attendee => {
//     if(attendee.channel == 'Web') {
//       totalWebCount += 1;
//     } else {
//       totalMobileCount += 1;
//     }
//   });

//   allRow.webCheckIns = Math.round(((totalWebCount / allRow.audience) * 100) / 5) * 5;
//   allRow.mobileCheckIns = Math.round(((totalMobileCount / allRow.audience) * 100) / 5) * 5;


//   engagementData.push(allRow);


//   //parent only
//   let parentRow = model;
//   parentRow.title = 'Parents Only'
//   parentRow.audience = data.totalParents;
//   parentRow.numAddCal = data.totalAddedToCalParents;
//   parentRow.percentAddCal = Math.round(((parentRow.numAddCal / allRow.numAddCal) * 100) / 5) * 5;
//   parentRow.numCheckedIn = this.getCheckIns(data, 'Parent').length;
//   parentRow.percentCheckedIn = Math.round(((parentRow.numCheckedIn / parentRow.audience) * 100) / 5) * 5;

  
//   let parentWebCount = 0;
//   let parentMobileCount = 0;

//   _.each(data.registrations, attendee => {
//     if(attendee.role == 'Parent') {
//       if(attendee.channel == 'Web') {
//         parentWebCount += 1;
//       } else {
//         parentMobileCount += 1;
//       }
//     }
//   });

//   parentRow.webCheckIns = Math.round(((parentWebCount / parentRow.audience) * 100) / 5) * 5;
//   parentRow.mobileCheckIns = Math.round(((parentMobileCount / parentRow.audience) * 100) / 5) * 5;


//   engagementData.push(parentRow);


//   //student only
//   let studentRow = model;
//   studentRow.title = 'Students Only'
//   studentRow.audience = data.totalStudents;
//   studentRow.numAddCal = data.totalAddedToCalStudents;
//   studentRow.percentAddCal = Math.round(((studentRow.numAddCal / allRow.numAddCal) * 100) / 5) * 5;
//   studentRow.numCheckedIn = this.getCheckIns(data, 'Student').length;
//   studentRow.percentCheckedIn = Math.round(((studentRow.numCheckedIn / studentRow.audience) * 100) / 5) * 5;

//   let studentWebCount = 0;
//   let studentMobileCount = 0;

//   _.each(data.registrations, attendee => {
//     if(attendee.role == 'Student'){
//       if(attendee.channel == 'Web') {
//         studentWebCount += 1;
//       } else {
//         studentMobileCount += 1;
//       }
//     }
//   });

//   studentRow.webCheckIns = Math.round(((studentWebCount / studentRow.audience) * 100) / 5) * 5;
//   studentRow.mobileCheckIns = Math.round(((studentMobileCount / studentRow.audience) * 100) / 5) * 5;

//   engagementData.push(studentRow);
//   return engagementData
// }

calculateBarWidth(num, total){
  let percent = num / total;
  let width = 120 * percent;
  return width
}

printPage() {
  window.print();
}

gotoCommLog() {
  this.router.navigate([this.role.toLocaleLowerCase(), 'communications-log']);
}

getBarChartData() {

  //get X Axis
  this.barChartLabels = this.getXAxis();


  //get Data
  for (let i = 0; i < this.barChartLabels.length; i++) {
    let countWeb = 0;
    let countMobile = 0;
    _.each(this.barData, item => {
      if(item.checkIn != null) {
        let time = moment(item.checkIn, 'HH:mm');
        if(time.isSame(moment(this.barChartLabels[i], 'HH:mm')) || time.isBetween(moment(this.barChartLabels[i], 'HH:mm'), moment(this.barChartLabels[i + 1], 'HH:mm'))){
          if(item.channel == 'Mobile'){
            countMobile += 1;
          } else {
            countWeb += 1;
          }
        }
        this.barChartData[0].data[i] = countWeb;
        this.barChartData[1].data[i] = countMobile;
      }
    })
  }


  // for (let i = 0; i < this.barChartLabels.length; i++) {
  //   let label = this.barChartLabels[i];
  //   let end = moment(label, 'HH:mm').add(10, 'm').format('HH:mm');
  //   label = `${label} - ${end}`
  //   console.log(label)
  // }


  this.barChartLabels.pop()
}

getXAxis(){

  let xAxis = [];
  let startTime = moment(this.eventData.startDate);
  let endTime = moment(this.eventData.endDate);
  let newTime = startTime;

 
  
  while(newTime.isSameOrBefore(endTime)){
    let interval = newTime.format('HH:mm');
    
    xAxis.push(interval);
    newTime = moment(newTime, 'HH:mm').add(10, 'm');
  }

  return xAxis
  
}


}
