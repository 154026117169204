import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-student-health-and-wellness',
  templateUrl: './student-health-and-wellness.component.html',
  styleUrls: ['./student-health-and-wellness.component.scss']
})
export class StudentHealthAndWellnessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }  

}
