import { Component, OnInit, Inject, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { IEmployeeContact } from './../../services/parent.services.models';
import { StudentsService } from '../../services/students.service';
import { ContactsService } from '../../services/contacts.service';
import { ProfileService } from '../../services/profile.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact-details-widget',
  templateUrl: './contact-details-widget.component.html',
  styleUrls: ['./contact-details-widget.component.scss']
})
export class ContactDetailsWidgetComponent implements OnInit {
  @Output() command: EventEmitter<any> = new EventEmitter<any>();
  @Input() customRole: string;
  role = this.profileService.profile.role;
  userId = this.profileService.profile.id;
  displayContactId:string;
  get isProd() {
    if(environment.environment_name == 'Production') return true
    else return false
  }

  get isStaff() {
    return this.profileService.profile.role !== 'Parent' && this.profileService.profile.role !== 'Student'
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public contact: any,
    private dialogRef: MatDialogRef<ContactDetailsWidgetComponent>,
    private router: Router,
    private studentService: StudentsService,
    private contactService: ContactsService,
    private profileService: ProfileService,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    console.log(this.isProd, this.role)

    this.profileService.getProfileById(this.contact.studentId || this.contact.id || this.contact.empCode).subscribe(profile => {
      this.contact = profile;
    })
    if(this.contact.fromEmContact){
      if(this.contact.emergencyContactId){
        this.contact.name = `${this.contact.firstName} ${this.contact.lastName}`;
        this.contact.role = 'Parent';
      } else {
        this.contact.name = `${this.contact.parentFirstName} ${this.contact.parentLastName}`;
        this.contact.role = 'Parent';
      }
    }
    if(this.contact.id == '00625399'){
      this.role = 'Counselor';
      this.contact.role  = 'Counselor';
    }
    if(this.contact.role == 'Student'){
      this.studentService.getStudentById(this.contact.empCode || this.contact.id).subscribe(res => {
        this.contact.dob = res.dateOfBirth;
      })
    }
    if(this.contact.id)
    this.displayContactId = this.contact.id.toString().replaceAll(/[^0-9]/g,'');
  }
  close() {
    this.dialogRef.close();
  }
  message() {
    // this.command.emit({ name: 'message', source: this.contact });
    this.router.navigate([`${this.role.toLocaleLowerCase()}/inbox/compose/` + this.contact.id]);
    this.dialogRef.close();
  }
  goStudentCcrg(student) {
    this.studentService.setStudent(student);
    this.router.navigate([`${this.role.toLocaleLowerCase()}/students/${student.empCode || student.id || student.studentId}/ccrg`]);
    this.dialogRef.close();
  }
  goContactProfile(contact) {
    if(contact.role === 'Parent'){
      contact.empFirstName = contact.parentFirstName;
      contact.empLastName = contact.parentLastName;
      contact.empCode = contact.id;
      contact.emailAddressTxt = contact.email;
    }
    console.log(contact)
    this.contactService.setContact(contact);
    this.router.navigateByUrl(`${this.role.toLocaleLowerCase()}/contacts/profile/${contact.id || contact.studentId || contact.empCode}`)
    this.dialogRef.close();
    // this.router.navigate([this.contact.role.toLocaleLowerCase(), 'contacts', 'profile', contact.id]);
  }

}
