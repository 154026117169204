import { SchoolYearService } from './school-year.service';
import { IRoleServices } from './IRoleServices';
import { ActivatedRoute } from '@angular/router';
import { IStudentResume } from './../components/student-list-widget/models/IStudentResume';
import { IStudentDetails } from "./../components/student-list-widget/models/IStudentDetails";
import { Injectable } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ApiService } from "./api.service";
import { ISchoolInfo } from "../components/student-list-widget/models/ISchoolInfo";
import {
  IStudentAttendances,
  IStudentIAPAttendance,
  IStudentBus,
  IStudentEmergencyContact,
  IStudentGateProgram,
  IStudentGrades,
  IStudentImmunization,
  IStudentReport,
  IStudentIAPBasicSkill,
  StudentIAPGraduationProgress,
} from "./student.service.models";
import { ContactModel } from '../components/contact-widget/models/ContactWidgetModel';
import { AppConfigService } from 'src/app/services/app-config.service';
import * as _ from 'lodash';
import * as m from 'moment';
import { isNumber } from 'lodash';
import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { IStudentELAReport } from "../components/students-timeline/student-testing/models/StudentELAReport";
import { IStudentMathReport } from "../components/students-timeline/student-testing/models/StudentMathReport";
import { IStudentReportGradeLevel } from '../components/students-timeline/student-testing/models/StudentReportGradeLevel';
@Injectable({
  providedIn: "root",
})
export class StudentsService implements IRoleServices {

  baseUrl: string = this.appConfig.configuration.environment.endPoints.customerServices; //"https://localhost:44315";
  reportBaseUrl: string = this.appConfig.configuration.environment.endPoints.reportServices;
  _cachedStudent: IStudentResume;
  constructor(
    private appConfig: AppConfigService,
    private api: ApiService,
    private reportApi: ApiService) {
    this.api.baseUrl = this.baseUrl;
    this.reportApi.baseUrl = this.reportBaseUrl;
    console.log(this.reportBaseUrl);
    }

  getContacts(id: string) : Observable<ContactModel[]>{
    let path = `${this.baseUrl}/v1/students/${id}/contacts`;
    return this.api.get(path).pipe(map((response)=>response as ContactModel[]));
  }
  getStudentById(id: string): Observable<IStudentDetails> {
    let path = `${this.baseUrl}/v1/students/${id}`;
    return this.api.get(path).pipe(
      map((response) => response as IStudentDetails),
      tap((response) => (this._cachedStudent = response))
    );
  }
  getStudentSchoolInfo(id: string): Observable<ISchoolInfo> {
    let path = `${this.baseUrl}/v1/students/${id}/schoolInfo`;
    return this.api.get(path).pipe(map((response) => response as ISchoolInfo));
  }
  getStudentPFTReport(id:string, year:string): Observable<any> {
    let path = `${this.baseUrl}/v1/students/${id}/pft?year=${year}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getStudentOlsatReport(id:string, year:string): Observable<any> {
    let path = `${this.baseUrl}/v1/students/${id}/olsat?year=${year}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getStudentPsatReport(id:string, year:string): Observable<any> {
    let path = `${this.baseUrl}/v1/students/${id}/psat?year=${year}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getStudentApReport(id:string, year:string): Observable<any> {
    let path = `${this.baseUrl}/v1/students/${id}/ap?year=${year}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getStudentReport(id:string, reportName:string,year:string): Observable<IStudentReport> {
    let path = `${this.baseUrl}/v1/students/${id}/report/${reportName}?year=${year}`;
    return this.api.get(path).pipe(map((response) => response as IStudentReport));
  }
  getStudentIReadyELAReport(studentId:string, reportYear:string): Observable<any> {
    let path = `${this.baseUrl}/v1/students/${studentId}/iready/ela?academicYear=${reportYear}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getStudentIReadyELAGradeLevel(studentId:string, grade:string): Observable<any> {
    let path = `${this.baseUrl}/v1/students/${studentId}/iready/ela/gradelevel?grade=${grade}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getStudentIReadyMathReport(studentId:string, reportYear:string): Observable<any> {
    let path = `${this.baseUrl}/v1/students/${studentId}/iready/math?academicYear=${reportYear}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getStudentIReadyMathGradeLevel(studentId:string, grade:string): Observable<any> {
    let path = `${this.baseUrl}/v1/students/${studentId}/iready/math/gradelevel?grade=${grade}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getStudentAttendace(id: string): Observable<IStudentAttendances> {
    let path = `${this.baseUrl}/v1/students/${id}/attendance`;
    return this.api.get(path).pipe(map((response) => response as IStudentAttendances));
  }
  getStudentIAPAttendace(id: string): Observable<IStudentIAPAttendance> {
    let path = `${this.baseUrl}/v1/students/${id}/iap/attendance`;
    return this.api.get(path).pipe(map((response) => response as IStudentIAPAttendance));
  }
  getStudentIAPBasicSkills(id: string): Observable<IStudentIAPBasicSkill> {
    let path = `${this.baseUrl}/v1/students/${id}/iap/basicskills`;
    return this.api.get(path).pipe(map((response) => response as IStudentIAPBasicSkill));
  }
  getStudentIAPCollegeCareer(id: string): Observable<StudentIAPGraduationProgress> {
    let path = `${this.baseUrl}/v1/students/${id}/iap/collegecareer`;
    return this.api.get(path).pipe(map((response) => response as StudentIAPGraduationProgress));
  }
  getStudentDiscipline(id: string): Observable<any> {
    let path = `${this.baseUrl}/v1/students/${id}/discipline`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getStudentImmunization(id: string): Observable<IStudentImmunization[]> {
    let path = `${this.baseUrl}/v1/students/${id}/immunization`;
    return this.api.get(path).pipe(map((response) => response as IStudentImmunization[]));
  }
  getStudentGrades(id: string): Observable<IStudentGrades> {
    let path = `${this.baseUrl}/v1/students/${id}/grades`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getStudentGradesSilent(id: string): Observable<IStudentGrades> {
    let path = `${this.baseUrl}/v1/students/${id}/grades`;
    return this.api.getSilent(path).pipe(map((response) => response as any));
  }
  getStudentGradesByYear(id: string,year:string): Observable<IStudentGrades> {
    let path = `${this.baseUrl}/v1/students/${id}/grades/${year}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getStudentGpa(id: string,year:string): Observable<IStudentGrades> {
    let path = `${this.baseUrl}/v1/students/${id}/gpa?year=${year}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getStudentAssignments() : Observable<any> {
    let id = this.currentStudent().schoologyID || this.currentStudent().schoologyId
    let path = `${this.baseUrl}/v1/students/${id}/assignments/grades`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getStudentAssignmentsByStudent(studentId:any) : Observable<any> {
    let path = `${this.baseUrl}/v1/students/${studentId}/assignments/grades`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getStudentAssignmentsBySchoologyId(studentId:string,schoologyId:string) : Observable<any> {
    let path = `${this.baseUrl}/v1/students/${studentId}/assignments`;
    return this.api.get(path).pipe(catchError(err=>of(err)),map((response) => response as any));
  }

  getStudentAssignmentsByStudentId(studentId:string) : Observable<any> {
      return this.getStudentAssignmentsBySchoologyId(studentId,null);
  }
  getStudentGate(id: string): Observable<IStudentGateProgram> {
    let path = `${this.baseUrl}/v1/students/${id}/gate`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getStudentGateReport(id: string): Observable<IStudentGateProgram> {
    let path = `${this.baseUrl}/v1/students/${id}/gatereport`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getStudentBusRoutes(id: string): Observable<IStudentBus> {
    let path = `${this.baseUrl}/v1/students/${id}/transportation/routes`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getStudentBusRouteDelays(id: string): Observable<IStudentBus> {
    let path = `${this.baseUrl}/v1/students/${id}/transportation/delays`;
    return this.api.get(path).pipe(map((response) => response as any));
  }

  getStudentEmergencyContacts(
    studentId: string,
    id: string
  ): Observable<IStudentEmergencyContact> {
    let path = `${this.baseUrl}/v1/students/${studentId}/${id}/emergencyContacts`;
    return this.api
      .get(path)
      .pipe(map((response) => response as IStudentEmergencyContact));
  }

  getParentsByStudent(studentId: string):Observable<any> {
    let path = `${this.baseUrl}/v1/students/${studentId}/parents`;
    return this.api.get(path).pipe(map((response) => response as any));
  }


  updateStudentEmergencyContacts(id: string, studentId: string, contacts: any): Observable<IStudentEmergencyContact> {
    const path = `${this.baseUrl}/v1/students/${id}/${studentId}/emergencyContacts`
    return this.api.put(path, contacts).pipe(map((response) => response as any));
  }


  setStudent(student:IStudentResume){
    localStorage.setItem("selectedStudent",JSON.stringify(student))
  }
  currentStudent() {
    var student = localStorage.getItem("selectedStudent");
    var jsonStudent = JSON.parse(student);
    try {
      if(isNumber(jsonStudent.gradeCode)) {
        jsonStudent.gradeLevel = parseInt(jsonStudent.gradeCode)
      }else{
        jsonStudent.gradeLevel = jsonStudent.gradeCode
      }
      return jsonStudent;
    }
    catch(error) {
      console.log(error)
    }
  }
  photos : any = []
  getPicture(id:any){
    let path = `${this.baseUrl}/v1/students/${id}/picture`;

    return this.api.cached(path,{spinner:'no'}).pipe(
      map((response) => response as any)
      );
  }
  getStudentElp(id: any) {
    let path = `${this.baseUrl}/v1/students/${id}/englishLearners`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getStudentPdfReport(id: any,report:any,lang:any) : Observable<any> {
    let path = `${this.reportBaseUrl}/v1/students/${id}/pdf?report=${report}&lang=${lang}`;
    return this.reportApi.getReport(path).pipe(map((response) => response as any));
  }
  getStudentTracking(id: any,route: any) {
    let path = `${this.baseUrl}/v1/students/${id}/tracking?route=${route}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  getStudentDisciplineLetter(id: any,participantId:any) : Observable<any> {
    let path = `${this.baseUrl}/v1/students/${id}/discipline-letter/${participantId}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
  calculateAttendance(attendance: any){
    let response = {
      absense: 0,
      tardy: 0
    };
    var today = m().format("MM/DD/YYYY");
     if(attendance.termList){
       attendance.termList.forEach(term => {
        _.each(term.courseList,(course)=>{
          if(course.absence != "0"){
            response.absense = _.filter(course.daysAbsent,c=>c.reasonDate == today).length;
          }
          if(course.tardy != "0"){
            response.tardy = _.filter(course.daysTardy,c=>c.reasonDate == today).length;
          }
        })
       });
      }
    return response;
  }
  getSchoologyRemoteLogin(platform:string,studentId: string) : Observable<any>{
    let path = `${this.baseUrl}/v1/students/${studentId}/schoologyRemoteLogin?platform=${platform}`;
    return this.api.get(path).pipe(map((response)=> response as any[]));
  }

  getStudentGraduationReport(id:string): Observable<any> {
    let path = `${this.reportBaseUrl}/v1/students/${id}/graduation-report`;
    return this.reportApi.getReport(path).pipe(map((response) => response as any));
  }

  getStudentYears(id:string): Observable<any> {
    let path = `${this.baseUrl}/v1/students/${id}/grades-year`;
    return this.api.get(path).pipe(map((response) => response as any));
  }

  getStudentHistoricalGrades(id:string, fiscalYear:string): Observable<any> {//sdsdd
    let path = `${this.baseUrl}/v1/students/${id}/historical-grades/${fiscalYear}`;
    return this.api.get(path).pipe(map((response) => response as any));
  }

  getParentsByStudentId(id:string): Observable<any> {
    let path = `${this.baseUrl}/v1/students/${id}/parents`;
    return this.api.get(path).pipe(map((response) => response as any));
  }

  getStudentAttendanceReport(id:string): Observable<any> {
    let path = `${this.baseUrl}/v1/students/${id}/attendance-report`;
    return this.api.get(path).pipe(map((response) => response as any));
  }
}
