import { Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-relative-placement-icon',
  templateUrl: './relative-placement-icon.component.html',
  styleUrls: ['./relative-placement-icon.component.scss']
})
export class RelativePlacementIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input() relativePlacement: 'Early On Grade Level' | 'Mid or Above Grade Level' | 'Not Assessed' | '1 Grade Level Below' | '3 or More Grade Levels Below' | '2 Grade Levels Below';

  iReadyImageMap = {
    'Early On Grade Level': 'assets/img/icons/iready/early_on_grade_level.svg',
    'Mid or Above Grade Level': 'assets/img/icons/iready/mid_or_above_grade_level.svg',
    'Not Assessed': 'assets/img/icons/iready/not_assessed.svg',
    '1 Grade Level Below': 'assets/img/icons/iready/one_grade_level_below.svg',
    '3 or More Grade Levels Below': 'assets/img/icons/iready/three_or_more_levels_below.svg',
    '2 Grade Levels Below': 'assets/img/icons/iready/two_grade_levels_below.svg',
  };

  getIconPath(): string {
    return this.iReadyImageMap[this.relativePlacement];
  }
}
