import { AudienceService } from './../../services/audience.service';
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { Router, ActivatedRoute } from "@angular/router";

import * as moment from "moment";
import * as _ from "lodash";
import { TeacherService } from "src/app/shared/services/teacher.service";
import { CommunicationGroupService } from "../../services/communication-group.service";
import { ProfileService } from "../..//services/profile.service";
import { CommunicationService } from "../../services/communication.service";
import { ToastrService } from "ngx-toastr";
import { Observable, ReplaySubject } from "rxjs";
import { CertificateService } from "../../services/certificate.service";
import { StudentsService } from "../../services/students.service";
import { environment } from 'src/environments/environment';
import { AdministratorService } from "src/app/shared/services/administrator.service";

@Component({
  selector: "app-create-communication",
  templateUrl: "./create-communication.component.html",
  styleUrls: ["./create-communication.component.scss"],
})
export class CreateCommunicationComponent implements OnInit {
  disableDate: Date;
  disableEndDate: Date;
  
  get schoolRoomsFilter() {
    if (this.grade !== null) {
      return _.filter(this.schoolRooms, (s) => s.grade === this.grade);
    } else {
      this.dataSource = new MatTableDataSource<any>(this.allStudents);
      return [];
    }
  }

  pushList: any[] = [
    { color: "bg-green", selected: true, type: "success", label: "green" },
    { color: "bg-yellow", selected: false, type: "warning", label: "yellow" },
    { color: "bg-red", selected: false, type: "danger", label: "red" },
    // { color: 'bg-lightblue', selected: false, type: 'info', label: 'blue' },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public profileService: ProfileService,
    private communicationService: CommunicationService,
    private teacherServices: TeacherService,
    private cGroups: CommunicationGroupService,
    private toastr: ToastrService,
    private certificateServices: CertificateService,
    private studentsService: StudentsService,
    private audienceService: AudienceService,
    private adminService:AdministratorService
  ) {
    this.disableDate = new Date()
    this.disableEndDate = new Date()
    this.currentDate= new Date();
    this.isAdminhasAccess()
  }

  step1 = true;
  step2 = false;
  step3 = false;
  step4 = false;
  step5 = false;

  // types = ['inbox', 'push', 'calendar'];
  types = [
    // { type: "inbox", selected: true },
    { type: "push", selected: false },
    { type: "calendar", selected: false },
  ];
  type = "inbox";
  communicationTitle = null;
  inboxText: string;
  pushColor: any = "success";
  pushAlert: string;
  pushLink: string;
  pushClose = true;

  calendarType = "In Person";
  calendarTitle: string;
  calendarDesc: string;
  calendarImage: string;
  featureOnDashboard: boolean = false;
  featureIsPrivate: boolean = false;
  featureIsRsvpOnly: boolean = false;
  generateQR = true;
  calendarStartTime: Date = moment(new Date()).set('minute',0).toDate();
  calendarEndTime: Date = moment(new Date()).set('minute',15).toDate();
  calendarEventLink: string;
  calendarEventLocation: string;
  feedPreview = {
    title: "",
    description: "",
    postedBy: "",
    eventLink: "",
    eventLocation: "",
    eventTime: "",
    featureOnDashboard: false,
    type: "calendar",
    extraData: {},
  };

  mytime: Date = new Date();
  bsValue = new Date();
  bsConfig = {
    containerClass: "theme-angle",
  };

  alerts = {
    alerts: [],
  };

  uploadedImage: any;
  uploadedFile: any;
  uploadedFileName: string = "";

  teacherInfo: any = {
    photo: "assets/img/user/02.jpg",
    name: "Jane",
    role: "Teacher",
  };
  logDate = moment(new Date()).format("MMM Do, YYYY");

  students: any[] = [];
  displayedColumns: string[] = [
    "select",
    "photo",
    "studentId",
    "firstName",
    "lastName",
  ];
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  selectedNum = 0;
  schoolRooms: any = [];
  schoolRoom: any = null;
  grades: number[] = [1, 2, 3, 4, 5, 6];
  grade: any = null;
  classes: string[] = ["class name", "class name", "class name"];
  class: any = null;
  groups: string[] = ["group name", "group name", "group name"];
  group: any = null;
  isSent: any = null;

  studentsCount = 0;
  communicationGroups = [];
  communicationGroup = null;
  allStudents = [];
  staffId = null;
  audienceModel = null;
  editMode: boolean = false;
  communicationId: string = null;
  currentDate : Date;
  initialEntry : any;
  isAdminAccess: boolean = false;
  allSchools: boolean = false;
  allDay = false;

  get isProd() {
    if(environment.environment_name == 'Production') return true
    else return false
  }

  get isTest() {
    if(environment.environment_name == 'Test') return true
    else return false
  }

  get userRole() {
    return this.profileService.profile.role
  }
  isAdminhasAccess() {
    const profile = this.profileService.profile;
  
    return this.adminService.allowAdminAccount(profile.firstName, profile.lastName, profile.email).subscribe(result => {
      if(result!= null){
        this.isAdminAccess = true;
        this.types = this.types.filter(type => type.type !== 'inbox' && type.type !== 'calendar')
      this.selectType('push');
      }
    })
  }
  ngOnInit(): void {

    //Init prod items (remove inbox)
    if(this.isProd && this.userRole == 'Administrator'){
      this.types = this.types.filter(type => type.type !== 'inbox')
    }


    if (this.route.snapshot?.data?.isEdit) {
      this.editMode = this.route.snapshot?.data?.isEdit;
      // FIXME: real service
      this.communicationId = this.route.snapshot.params.id;
      this.communicationService
        .getCommById(
          this.profileService.identity.userIdRole,
          this.communicationId
        )
        .subscribe((res: any) => {
          const entry = res[0];
          this.initialEntry = entry;
          this.type = entry.type;
          this.selectType(this.type);
          this.mytime = new Date(res[0].sendTime);
          this.bsValue = new Date(res[0].sendTime);
          this.step2 = true;
          this.communicationTitle = entry.name;
          if (entry.sent == null) {
            this.isSent = entry.sent;
          }
          if (entry.sent != null) {
            this.isSent = true;
          }

          if (entry.type === "inbox") {
            this.inboxText = entry.content;
          }

          if (entry.type === "push") {
            const extraData = entry.extraData;
            this.pushColor = extraData.pushColor;
            var index = this.pushList.findIndex(
              (a) => a.type == extraData.pushColor
            );
            this.selectColor({ selected: false }, index);
            this.pushColor = extraData.pushColor;
            this.pushAlert = extraData.pushAlert;
            this.pushLink = extraData.pushLink;
            this.pushClose = extraData.pushClose;
          }

          if (entry.type === "calendar") {
            console.log(entry);
            const extraData = entry.extraData;
            this.calendarDesc = entry.content;
            this.calendarType = extraData.eventType;
            this.calendarStartTime = new Date(extraData.startTime);
            this.calendarEndTime = new Date(extraData.endTime);
            this.featureOnDashboard = extraData.feature;
            this.uploadedFile = extraData.eventFile;
            this.uploadedFileName = extraData.eventFileName;
            this.calendarEventLink = extraData.eventLink;
            this.calendarEventLocation = extraData.location;
            this.featureIsPrivate = extraData.isPrivate;
            this.featureIsRsvpOnly = extraData.isRsvpOnly;
            this.allDay = extraData.startTime == extraData.endTime;
            if (extraData.eventPicture) {
              this.certificateServices
                .getThumbnails()
                .toPromise()
                .then((response) => {
                  let s3public =
                    "https://" +
                    response.s3PublicRepository +
                    ".s3.us-west-2.amazonaws.com/";
                  this.uploadedImage = `${s3public}${extraData.eventPicture}`;
                });
            }
          }
        });
    }

    this.route.params.subscribe((data) => {
      if (data.type) {
        switch (data.type) {
          case "message":
            this.selectType("inbox");
            break;
          case "alert":
            this.selectType("push");

            break;
          case "event":
            this.selectType("calendar");
            break;

          default:
            break;
        }
      }
    });

    this.dataSource = new MatTableDataSource<any>([]);
    this.staffId = this.profileService.identity.userIdRole;
    this.teacherServices
      .getSchoolRoomsByStaffId(this.staffId)
      .subscribe((schoolRooms) => {
        this.schoolRooms = schoolRooms;
        this.grades = _.uniq(_.map(schoolRooms, (i) => i.grade));
      });
    this.cGroups.getCommunicationGroups(this.staffId).subscribe((cg) => {
      this.communicationGroups = cg;
    });
  }
  audienceChange($event) {
    this.audienceModel = $event;
    this.allSchools = this.audienceModel.allSchools;
    console.log(this.audienceModel)

  }



  selectType(type) {
    _.each(this.types, (type) => {
      type.selected = false;
    });
    let selected = _.find(this.types,(i)=> i.type == (type as any));
    selected.selected = true;
    this.type = selected.type;
    // this.types[idx].selected = true;
  }

  cancel() {
    let role = this.profileService.profile.role.toLocaleLowerCase();
    this.router.navigate([`${role}/communications-log`]);
  }

  submit() {
    let role = this.profileService.profile.role.toLocaleLowerCase();
    let contentText: any;
    let extraData = {} as any;
    if (this.type === "inbox") {
      contentText = this.inboxText;
    }
    if (this.type === "push") {
      contentText = this.pushAlert;
      extraData = {
        pushColor: this.pushColor,
        pushAlert: this.pushAlert,
        pushLink: this.pushLink,
        pushClose: this.pushClose,
      };
    }
    if (this.type === "calendar") {
      contentText = this.calendarDesc;
      extraData = {
        calendarTitle: this.calendarTitle,
        calendarDesc: this.calendarDesc,
        eventPicture: this.uploadedImage,
        eventFile: this.uploadedFile,
        startTime: moment(this.calendarStartTime).format('YYYY-MM-DDTHH:mm:ss'),
        endTime: moment(this.calendarEndTime).format('YYYY-MM-DDTHH:mm:ss'),
        eventType: this.calendarType,
        feature: this.featureOnDashboard,
        eventLink: this.calendarEventLink,
        location: this.calendarEventLocation,
        postedBy: this.profileService.profile.fullName,
        eventFileName: this.uploadedFileName,
        isPrivate: this.featureIsPrivate,
        isRsvpOnly: this.featureIsRsvpOnly,
        allDay: this.allDay,
      };
    }
    //add shared
    extraData.userSelection = this.audienceModel?.userSelection;
    if(this.audienceModel?.schoolCode){
      extraData.locations = JSON.parse(this.audienceModel?.schoolCode);
      if(extraData.locations.length == 0)
        extraData.locations = null;
    }
    extraData.gradeId = this.audienceModel?.gradeId;
    extraData.schoolRoomId = this.audienceModel?.schoolRoomId?.toString();
    extraData.Parents = this.audienceModel?.includeParents;
    extraData.studentCount = this.audienceModel?.studentCount;
    extraData.parentCount = this.audienceModel?.parentCount; 
    extraData.allSchools = this.audienceModel?.allSchools;
    const request = {
      ...this.audienceModel,
      name: this.communicationTitle,
      type: this.type,
      startTime: moment(this.mytime).format("YYYY-MM-DDTHH:mm:ss"),
      startDate: moment(this.mytime).format("YYYY-MM-DDTHH:mm:ss"),
      content: contentText,
      staffId: this.profileService.identity.userIdRole,
      extraData: JSON.stringify(extraData),
      allStudents: this.audienceModel?.includeStudents,
      allParents: this.audienceModel?.includeParents,
    };

    if (this.editMode) {
      request.id = this.communicationId;
      this.communicationService.updateCommunication(request).subscribe(
        () => {
          if (this.type == "inbox") {
            this.toastr.success("Communication Updated: Message Scheduled");
          }
          if (this.type == "push") {
            this.toastr.success("Communication Updated: Important Alert Sent");
          }
          if (this.type == "calendar") {
            this.toastr.success("Communication Updated: Calendar Event Sent");
          }

          this.router.navigate([role, "communications-log"]);
        },
        (err) => {
          this.toastr.error("Error: Communication not sent");
        }
      );
    } else {
      this.communicationService.createCommunication(request).subscribe(
        () => {
          if (this.type == "inbox") {
            this.toastr.success("Communication Created: Message Scheduled");
          }
          if (this.type == "push") {
            this.toastr.success("Communication Created: Important Alert Sent");
          }
          if (this.type == "calendar") {
            this.toastr.success("Communication Created: Calendar Event Sent");
          }

          this.router.navigate([role, "communications-log"]);
        },
        (err) => {
          this.toastr.error("Error: Communication not sent");
          console.log(err);
        }
      );
    }
  }

  fileChangeListener($event, type) {
    console.log($event);
    if (type == "image") {
      const image: File = $event.target.files[0];
      this.convertFiletoB64(image).subscribe((base64) => {
        this.uploadedImage = `data:${image.type};base64,${base64}`;
      });
    }

    if (type == "file") {
      const file: File = $event.target.files[0];
      this.convertFiletoB64(file).subscribe((base64) => {
        this.uploadedFileName = file.name;
        this.uploadedFile = `data:${file.type};base64,${base64}`;
      });
    }
  }

  convertFiletoB64(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) =>
      result.next(btoa(event.target.result.toString()));
    return result;
  }

  selectColor(item, idx) {
    this.pushList.forEach((res: any) => (res.selected = false));
    this.pushList[idx].selected = true;
    this.pushColor = this.pushList[idx].type;
  }

  timeChange(newVal) {
    this.mytime = newVal;
  }

  calendarTimeChange(newVal, type) {
    var currentDate = new Date();
    
    if (type == "start") {
      this.calendarStartTime = newVal;

      if (this.calendarStartTime > currentDate){
        this.calendarEndTime = this.calendarStartTime;
        this.disableEndDate = this.calendarStartTime;
      }else{
        this.calendarEndTime = currentDate;
        this.disableEndDate = currentDate;
      }
    }

    if (type == "end") {
      this.calendarEndTime = newVal;
    }
  }

  minDate: any;

  checkAllDay() {
    this.allDay = !this.allDay;
    if (this.allDay) {
      this.calendarStartTime.setHours(0, 0, 0, 0);
      this.calendarEndTime = this.calendarStartTime;
    }
  }

  preview() {
    if (this.type === "push") {
      this.closeAlert(0);
      let message;
      if (this.pushLink) {
        let validUrl = window.decodeURIComponent(this.pushLink);
        validUrl = validUrl.trim().replace(/\s/g, "");
        if (/^(:\/\/)/.test(validUrl)) {
          this.pushLink = `http:${validUrl}`;
        } else if (!/^(f|ht)tps?:\/\//i.test(validUrl)) {
          this.pushLink = `http://${validUrl}`;
        } else {
          this.pushLink = validUrl;
        }
        message = `<p class="mb-0"><a target="_blank" class="alert-link pl-3 text-info"  href="${this.pushLink}" v3-translate>${this.pushAlert}</a></p>`;
      }
      if (!this.pushLink) {
        message = `<p class="mb-0"><span v3-translate>${this.pushAlert}</span></p>`;
      }
      this.alerts.alerts.push({
        id: "",
        message: message,
        type: this.pushColor,
      });
    } else if (this.type === "inbox") {
    } else if (this.type === "calendar") {
      this.feedPreview.title = this.communicationTitle;
      this.feedPreview.description = this.calendarDesc;
      this.feedPreview.postedBy = this.profileService.profile.fullName;
      this.feedPreview.eventLink = this.calendarEventLink;
      this.feedPreview.eventLocation = this.calendarEventLocation;
      this.feedPreview.featureOnDashboard = this.featureOnDashboard;
      this.feedPreview.extraData = {
        calendarTitle: this.calendarTitle,
        calendarDesc: this.calendarDesc,
        eventPicture: this.uploadedImage,
        eventFile: this.uploadedFile,
        startTime: this.calendarStartTime,
        endTime: this.calendarEndTime,
        eventType: this.calendarType,
        feature: this.featureOnDashboard,
        eventLink: this.calendarEventLink,
        location: this.calendarEventLocation,
        postedBy: this.feedPreview.postedBy,
        content: this.calendarDesc,
        allDay: this.allDay
      };
      console.log(this.feedPreview)
    }
  }

  enableUpdateCommunication(): boolean{
    if(!this.editMode){
      return false;
    }
  var updated = false
  if(this.initialEntry.type === "inbox"){
    updated = this.initialEntry.name != this.communicationTitle || this.initialEntry.content != this.inboxText || this.compareTime(this.mytime,this.initialEntry.sendTime) ||
  this.compareDate(this.mytime,this.initialEntry.sendTime) 
}
else if(this.type === "push"){
  updated = this.initialEntry.name != this.communicationTitle || this.initialEntry.extraData.pushAlert != this.pushAlert
  || this.initialEntry.extraData.pushColor != this.pushColor 
  || this.initialEntry.extraData.pushLink != this.pushLink
   || this.compareTime(this.mytime,this.initialEntry.sendTime) ||
  this.compareDate(this.mytime,this.initialEntry.sendTime) 
}
else{
updated =
this.initialEntry.name != this.communicationTitle ||
this.initialEntry.extraData.calendarDesc != this.calendarDesc ||
 this.initialEntry.extraData.eventPicture != this.uploadedImage||
 this.initialEntry.extraData.eventFile != this.uploadedFile||
 this.compareTime(this.initialEntry.extraData.startTime,this.calendarStartTime)||
 this.compareTime(this.initialEntry.extraData.endTime, this.calendarEndTime)||
 this.initialEntry.extraData.feature!= this.featureOnDashboard||
 this.initialEntry.extraData.eventLink != this.calendarEventLink||
 this.initialEntry.extraData.location != this.calendarEventLocation||
 this.initialEntry.extraData.eventFileName != this.uploadedFileName||
 this.initialEntry.extraData.isPrivate != this.featureIsPrivate||
 this.initialEntry.extraData.isRsvpOnly != this.featureIsRsvpOnly ||
   this.compareTime(this.mytime,this.initialEntry.sendTime) ||
  this.compareDate(this.mytime,this.initialEntry.sendTime) 
}

return updated || this.audienceModel != null
  }

  compareTime(earliertime, newtime) : boolean {
    const myTime = new Date(earliertime);
const sendTime = new Date(newtime);

// Extract the time values
const myTimeHours = myTime.getHours();
const myTimeMinutes = myTime.getMinutes();
const myTimeSeconds = myTime.getSeconds();

const sendTimeHours = sendTime.getHours();
const sendTimeMinutes = sendTime.getMinutes();
const sendTimeSeconds = sendTime.getSeconds();

// Compare the time values
const isSameTime =
  myTimeHours === sendTimeHours &&
  myTimeMinutes === sendTimeMinutes &&
  myTimeSeconds === sendTimeSeconds;
  
  return !isSameTime;
  }

  compareDate(earliertime, newtime) : boolean {
    const bsValue = new Date(earliertime);
const sendTime = new Date(newtime);

// Extract the time values
// Extract the date values
const bsValueYear = bsValue.getFullYear();
const bsValueMonth = bsValue.getMonth();
const bsValueDay = bsValue.getDate();

const sendTimeYear = sendTime.getFullYear();
const sendTimeMonth = sendTime.getMonth();
const sendTimeDay = sendTime.getDate();

// Compare the date values
const isSameDate =
  bsValueYear === sendTimeYear &&
  bsValueMonth === sendTimeMonth &&
  bsValueDay === sendTimeDay;
  
  return !isSameDate;
  }

  closeAlert(index) {
    this.alerts.alerts.splice(index, 1);
  }
}
