import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ProfileService } from "../../services/profile.service";
import { RoleServiceFactoryService } from "../../services/role-service-factory.service";
import { IEmployeeContact } from "./../../services/parent.services.models";
import { environment } from "src/environments/environment";
import { SchoolService } from "../../services/school.service";
import { StudentsService } from "../../services/students.service";

@Component({
  selector: "app-contact-list-widget",
  templateUrl: "./contact-list-widget.component.html",
  styleUrls: ["./contact-list-widget.component.scss"],
})
export class ContactListWidgetComponent implements OnInit {
  @Input() model: IEmployeeContact = null;
  @Input() printPage = false;
  @Output() command: EventEmitter<any> = new EventEmitter<any>();

  role = this.profileService.role;
  schoolName: string;
  dob: string;
  enrollmentStatus: string = null;
  get isProd(): boolean {
    let env = environment.environment_name;
    if(env === 'Development') return false
    else if(env === 'Stage') return false
    else return true
  }
  get isAdmin(): boolean {
    return this.profileService.profile.role == this.rs.AdminRole
  }

  get isTeacher(): boolean {
    return this.profileService.profile.role == this.rs.TeacherRole
  }

  get isCounselor(): boolean {
    return this.profileService.profile.role == this.rs.CounselorRole
  }

  get isParent(): boolean {
    return this.profileService.profile.role == this.rs.ParentRole
  }

  constructor(
    public rs: RoleServiceFactoryService,
    private profileService: ProfileService,
    private schoolService: SchoolService,
    private studentService: StudentsService
  ) {}

  ngOnInit(): void {

    if (this.printPage) {
      this.initPrintPage();
    }
    this.getParents();
    this.getSchoolName();
    this.getStudent();
  }

  getSchoolName(){
    this.schoolService.getSchoolsById([this.model.schoolCode]).subscribe(res => {
      this.schoolName = res[0].schoolName;
    })
  }

  getStudent() {
    if(this.model.role == this.rs.StudentRole){
      this.studentService.getStudentById(this.model.empCode).subscribe(res => {
        this.dob = res.dateOfBirth;
        this.enrollmentStatus = res.enrollmentStatus;
      })
    }

  }
  getParents(){
  
    this.profileService
      .getProfileById(this.model.empCode, false)
      .subscribe((profile) => {
        this.model.accountEnabled  =
        profile.accountEnabled == null ||
        profile.accountEnabled == true
          ? 'Y'
          : 'N';
       
       
      });
  }


  initPrintPage() {
    this.model.role = this.model.target;
    this.model.empCode = this.model.targetId;
    this.profileService
      .getProfileById(this.model.empCode)
      .subscribe((profile) => {
        this.model.empLastName = profile.lastName;
        this.model.empFirstName = profile.firstName;
        this.model.accountEnabled = profile.accountEnabled == true?'Y':'N';
      });
  }

  message() {
    this.command.emit({ name: "message", source: this.model });
  }
  profile() {
    this.command.emit({ name: "profile", source: this.model });
  }

  onImgError(event, model) {
    if (model.role === "Student") {
      event.target.src = "./assets/img/icons/student.svg";
    } else if (model.role === "Teacher") {
      event.target.src = "./assets/img/icons/principal.svg";
    } else if (model.role === "Principal") {
      event.target.src = "./assets/img/icons/teacher.svg";
    }
  }

  onImgTeacherError(event, getType) {
    console.log(getType.profile);
    const getVendorType =
      getType.profile == "principal"
        ? "Principal"
        : getType.profile == "employee"
        ? "Teacher"
        : "Student";
    if (getVendorType === "Student") {
      event.target.src = "./assets/img/icons/student.svg";
    } else if (getVendorType === "Teacher") {
      event.target.src = "./assets/img/icons/principal.svg";
    } else {
      event.target.src = "./assets/img/icons/teacher.svg";
    }
  }

  get studentsNum() {
    return this.model?.studentDistrictId?.length || 0;
  }
}
