import { RoleServiceFactoryService } from "./../../services/role-service-factory.service";
import { Component, Input, OnInit } from "@angular/core";
import { ProfileService } from "./../../../shared/services/profile.service";
import { DaysTipModel } from "./models/DaysTipModel";
import { CertificateService } from "src/app/shared/services/certificate.service";
import * as moment from "moment";
import { OAuthService } from "angular-oauth2-oidc";
import { environment } from "src/environments/environment";
import { AppConfigService } from "src/app/services/app-config.service";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { ParentsService } from "./../../../shared/services/parents.service";


@Component({
  selector: "app-tips-day-widget",
  templateUrl: "./tips-day-widget.component.html",
  styleUrls: ["./tips-day-widget.component.scss"],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 3000, noPause: false, showIndicators: true } }
 ]
})
export class TipsDayWidgetComponent implements OnInit {
  @Input() model: DaysTipModel[] = null;

  loaded: boolean;
  today = moment().day();
  role: any = null;
  isMotivation: boolean = false;

  isProd = environment.production;

  studentId: any;
  email: any;
  token: any;
  action: any;
  ssoUrl: any;
  sanitizedTitle: SafeHtml;
  hif_pp_token: any;
  hif_url: any;
  slides: { title: SafeHtml, url: string }[] = [];
  activeSlideIndex = 0; //nxl todo

  constructor(
    private certificateServices: CertificateService,
    private profileService: ProfileService,
    private rs: RoleServiceFactoryService,
    private oauthService: OAuthService,
    private appConfigService: AppConfigService,
    private sanitizer: DomSanitizer,
    private parentServices: ParentsService,
  ) {}

  settings = null;

  ngOnInit(): void {
    this.role = this.profileService.role;
    this.checkMotivation();
    for (let i=0; i < this.model.length; i++){
      this.sanitizedTitle = this.sanitizer.bypassSecurityTrustHtml(this.model[i]?.title);
      this.slides.push({title: this.sanitizedTitle, url: this.model[i].url })
      console.log(this.model[i], "admin-model");
    }
    //this.sanitizedTitle = this.sanitizer.bypassSecurityTrustHtml(this.model?.title);
    this.certificateServices.getThumbnails().subscribe((settings) => {
      this.settings = settings;
    });

    console.log(this.model, "model");
  }

  getTitle() {
    if (this.role !== this.rs.ParentRole && this.role !== this.rs.StudentRole) {
      switch (this.today) {
        case 1:
          return "Mindful Monday";
        case 2:
          return "Grati-Tuesday";
        case 3:
          return "Wellness Wednesday";
        case 4:
          return "Thoughtful Thursday";
        case 5:
          return "Fun Friday";
        default:
          return "Weekend";
      }
    } else return;
  }

  checkMotivation() {
    if (this.role == "Counselor") {
      this.isMotivation = true;
    } else if (this.role == "Teacher") {
      this.isMotivation = true;
    } else if (this.role == "Administrator") {
      this.isMotivation = true;
    } else {
      this.isMotivation = false;
    }
  }

  initFormValues() {
    this.studentId = "";
    this.email = this.profileService.profile.email;
    var accessToken = this.profileService.parseJwt(
      this.oauthService.getAccessToken()
    );
    this.token = accessToken["idcs-auth-token"];
    }

  requestDevice(url) {
    //console.log("the url: " + url);
      if (url == "https://device.lausd.net" ) {
        this.initFormValues();
        this.ssoUrl =
          this.appConfigService.configuration.environment.configuration.ppServices.externalLogin.myapps;
          console.log("deviceURL: " + this.ssoUrl);
        this.action = "DeviceRequest";
        setTimeout(() => {
          document.forms["stuEnr"].submit();
        }, 1000);
      } else if (url == "https://hif.lausd.net" ){
        this.getHif();
        setTimeout(() => {
        document.forms["hif"].submit();
      }, 1000);
      } else {
        window.open(url);
      }
  }

  getHif(){
    var accessToken = this.profileService.parseJwt(
      this.oauthService.getAccessToken()
    );
    var idcs_auth_token_value = accessToken["idcs-auth-token"];
    var sub_value = this.parentServices.parentId;
    var sub = "sub";
    var idcs_auth_token = "idcs_auth_token";
    //Create json object
    var hif_pp_token = {};
    hif_pp_token[sub] = sub_value;
    hif_pp_token[idcs_auth_token] = idcs_auth_token_value;
    hif_pp_token = JSON.stringify(hif_pp_token);

    var request = this.appConfigService.configuration.environment.configuration.ppServices.hif.incomeForm;
    //console.log("url: "+ request);
    this.hif_pp_token =  btoa(hif_pp_token.toString());//base64 encode token
    this.hif_url = request
  }
}
