import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/services/app-config.service';

@Injectable({
  providedIn: 'root'

})


export class CdnService {
  basePath: string = null;
 basePathCloudfront = this.appConfig.configuration.environment.basePathCloudfrontUrl;// "https://d1aeki9hjm9kl1.cloudfront.net/" 
 //this.appConfig.configuration.gaTrackingID
  get schoolBackgrounds(): string{
    return `${this.basePathCloudfront}school-images/`;
  };
  get resourceImages(): string{
    return `${this.basePath}lausd-resource-links/`;
  };
  get profileAvatars(): string{
    return `${this.basePath}profiles/avatars/`;
  };
  constructor(private appConfig: AppConfigService) { }
}
