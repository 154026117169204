import { CdnService } from "./../../../services/cdn.service";
import { AnnouncementAdminDetailsWidgetComponent } from "./../announcement-admin-post-widget/announcement-admin-details-widget/announcement-admin-details-widget.component";
import { IStudentResume } from "./../student-list-widget/models/IStudentResume";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy,
} from "@angular/core";
import { StudentDetailsModel } from "./models/StudentDetailsModel";
import { StudentsService } from "../../services/students.service";
import { timeStamp } from "console";
import { AnalyticsService } from "../../services/analytics.service";
import { ProfileService } from "../../services/profile.service";
import { MatDialog } from "@angular/material/dialog";
import { RemoveStudentModalComponent } from "../remove-student-modal/remove-student-modal.component";
import { ParentsService } from "../../services/parents.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";


@Component({
  selector: "app-student-details-widget",
  templateUrl: "./student-details-widget.component.html",
  styleUrls: ["./student-details-widget.component.scss"],
})
export class StudentDetailsWidgetComponent implements OnInit, OnDestroy {
  @Input() studentDashboard;
  @Input() student: IStudentResume = null;
  @Input() onStudentPage: boolean = false;
  @Output() selected: EventEmitter<IStudentResume> = new EventEmitter();
  // async component
  env = environment;  
  loaded: boolean;  
  imgPath: string = null;
  routeEnabled = false;
  schoolName = null;
  schoolImg = null;
  absence = 0;
  tardy = 0;

  studentName: string = null;
  firstName: string = null;
  middleName: string = null;
  lastName: string = null;
  enrolled: string;

  fontSize1 = false;
  fontSize2 = false;
  fontSize3 = false;
  textTruncate = false;
  defaultFont = false;

  isNumberGrade = false;
  isDisabled = false;

  get isStudent() {
    return this.profileService.profile.role == 'Student'
  }

  get isParent() {
    return this.profileService.profile.role == 'Parent'
  }

  get gradeDescription(): String {
    switch (this.student.gradeLevel) {
      case "PK":
        return '<span class="text-secondary" v3-translate>PreKindergartener</span> @';
      case "K":
        return '<span class="text-secondary" v3-translate>Kindergartener</span> @';
      case "1":
        this.isNumberGrade = true;
        return '<span class="text-secondary">1st-</span>';
      case "2":
        this.isNumberGrade = true;
        return '<span class="text-secondary">2nd-</span>';
      case "3":
        this.isNumberGrade = true;
        return '<span class="text-secondary">3rd-</span>';

      default:
        this.isNumberGrade = true;
        return `<span class="text-secondary">${this.student.gradeLevel}th-</span>`;
    }
  }

  constructor(
    private studentService: StudentsService,
    private cdref: ChangeDetectorRef,
    private analytics: AnalyticsService,
    private cdnServices: CdnService,
    private profileService: ProfileService,
    private dialog: MatDialog,
    private parentServices: ParentsService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.studentService.getPicture(this.student.studentId).subscribe((path) => {
      this.imgPath = path.img;
    });
    if(this.student.enrollmentStatus == 'Y' && this.student.gradeLevel != 'EE'){

    this.studentService
      .getStudentAttendanceReport(this.student.studentId)
      .subscribe((attendance) => {
        if (attendance.studentid == null) {
          this.absence = 0;
        } else {
          this.absence = attendance.absenceCount;
        }
      });

    // get full name
    this.studentService
      .getStudentById(this.student.studentId)
      .subscribe((student) => {
        this.firstName = student.firstName;
        if (student.middleInitial != null) {
          this.middleName = student.middleInitial;
          this.middleName = this.middleName.slice(0, 1);
        } else {
          this.middleName = "";
        }
        this.lastName = student.lastName;
        console.log(this.firstName, this.middleName, this.lastName)
      });

    }else{
      this.firstName = this.student.firstName;
      this.lastName = this.student.lastName;
      this.studentName =
      this.firstName + " " + this.middleName + " " + this.lastName;
      this.checkNameLength(this.studentName);
      this.checkStudentStatus(this.student)
    }
    this.studentService
    .getStudentSchoolInfo(this.student.studentId)
    .subscribe((school) => {
      this.schoolName = school.schoolName;
      this.schoolImg = `${this.cdnServices.schoolBackgrounds}${school.costCenterCode}/${school.costCenterCode}_1.jpg`;
      this.enrolled = school.enrollmentStatus;
      this.student.enrollmentStatus = school.enrollmentStatus;
      this.cdref.detectChanges();
    });


  }

  checkEnrolled(enrolled: string) {
    return enrolled === "Y" ? true : false;
  }

  checkNameLength(name) {
    if (name.length > 34) {
      this.fontSize3 = true;
      this.textTruncate = true;
    } else if (name.length > 29) {
      this.fontSize3 = true;
    } else if (name.length > 25) {
      this.fontSize2 = true;
    } else if (name.length > 20) {
      this.fontSize1 = true;
    } else {
      this.defaultFont = true;
    }
    return;
  }

  studentStatus = 0;
  checkStudentStatus(student) {

    this.isDisabled = student.verified == 'N' || student.enrollmentStatus === 'N' || student.gradeLevel === 'EE';
    if (student.gradeLevel == 'EE' ||  student.gradeCode == 'EE'){
      this.studentStatus = 3
    } else if(student.enrollmentStatus === 'Y' && student.verified === 'Y'){
      this.studentStatus = 0
    } else if (student.enrollmentStatus === 'Y' && student.verified === 'N'){
      this.studentStatus = 1
    } else {
      this.studentStatus = 2;
    }
  }

  removeStudent() {
    // this.modalServices.show();

    this.dialog
    .open(RemoveStudentModalComponent, {
      data: {
        student: this.student,
      },
      minWidth: '322px'
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        this.parentServices.removeStudent(this.student.studentId).subscribe(() => {
          this.parentServices.getStudentsByParentNoCache().subscribe(() => {

          })
          this.router.navigateByUrl("/parent/students");
          this.toastr.success(
            `${this.firstName} ${this.lastName} successfully removed. However, it could take a few minutes before the student is fully removed from this account.`
          );
        }, error => {
          this.toastr.error(
            `Could not delete ${this.firstName} ${this.lastName}. Try again.`
          );
        });
      }
    });
  }

  studentImage(student: IStudentResume) {}
  pickupEnabled: boolean = false;
  dropoffEnabled: boolean = false;
  processRoutes(bus) {
    if (bus.routes.length > 0) {
      this.routeEnabled = true;
      this.pickupEnabled = bus.routes[0].stopLocation != null;
      this.dropoffEnabled = bus.routes[1].stopLocation != null;
    }
  }
  details(student: IStudentResume) {
    if (this.checkEnrolled(student.enrollmentStatus)) {
      this.analytics.sendEvent("User Click", "Student Details");
      this.selected.emit(student);
    }
    if (!this.checkEnrolled(student.enrollmentStatus) && this.isStudent) {
      this.analytics.sendEvent("User Click", "Student Details");
      this.selected.emit(student);
    }
  }

  ngOnDestroy() {
    this.cdref.detach();
  }
}

export class AppComponent {}
